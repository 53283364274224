import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Loader } from "semantic-ui-react";
import Layout from './hoc/layout';
import Auth from './hoc/auth'
const Login = React.lazy(() => import('./components/Login'));
const ManageInventory = React.lazy(() => import('./components/ManageInventory'));
const Profile = React.lazy(() => import('./components/Profile'));
const Amenities = React.lazy(() => import('./components/Amenities'));
const PageNotFound = React.lazy(() => import('./components/views/pageNotFound'))
const ManagePassword = React.lazy(() => import('./components/ManagePassword'));
const Policy = React.lazy(() => import('./components/Policy'));
const PolicyUpdate = React.lazy(() => import('./components/PolicyUpdate'));
const ManageSoldout = React.lazy(() => import('./components/ManageSoldout'))
const UpcomingBookings = React.lazy(() => import('./components/Bookings'))
const HotelRegister = React.lazy(() => import('./components/RegisterHotel'))
const AdminRegistration = React.lazy(() => import('./components/AdminRegistration'))
const UserAccess = React.lazy(() => import('./components/UserAccess'))
const BulkAccountSearch = React.lazy(() => import('./components/bulkAccountSearch'))
const BulkAdminSearch = React.lazy(() => import('./components/bulkAdminSearch'))
const Hotelfinance = React.lazy(()=>import('./components/Hotel_Finance'))
const ExperienceBooking = React.lazy(()=>import('./components/ExperienceBooking'))
const Payment = React.lazy(()=>import("./components/Payment"));
const Agreement = React.lazy(()=>import('./components/Agreement'));
const ForeignGuest = React.lazy(() => import('./components/ForeignGuestModal'))

const Routes = () => {
  return (
    <Layout>
      <Suspense fallback={<Loader active />}>
        <Switch>
          <Route path="/bookings" exact component={Auth(UpcomingBookings, true)} />
          <Route path="/register-hotel/:id" exact component={Auth(HotelRegister, true)} />
          <Route path="/hotelfinance" exact component={Auth(Hotelfinance, true)} />
          <Route path="/user-access" exact component={Auth(UserAccess, true)} />
          <Route path="/profile" exact component={Auth(Profile, true)} />
          <Route path="/amenities" exact component={Auth(Amenities, true)} />
          <Route path="/admin-registration" exact component={Auth(AdminRegistration, true)} />
          <Route path="/sold-out" exact component={Auth(ManageSoldout, true)} />
          <Route path="/policy" exact component={Auth(Policy, true)} />
          <Route path="/policy-update" exact component={Auth(PolicyUpdate, true)} />
          <Route path="/password-change" exact component={Auth(ManagePassword, true)} />
          <Route path="/force-password-change" exact component={Auth(ManagePassword, false)} />
          <Route path="/login" exact component={Auth(Login, false)} />
          <Route path="/inventory" exact component={Auth(ManageInventory, true)} />
          <Route path="/experiences" exact component={Auth(ExperienceBooking, true)} />
          <Route path="/bulk-account-search" exact component={Auth(BulkAccountSearch, true)} />
          <Route path="/bulk-admin-search" exact component={Auth(BulkAdminSearch, true)} />
          <Route path="/payment/:error" exact component={Payment} />
          <Route path="/" exact component={Auth(UpcomingBookings, true)} />
          <Route path="/agreement" exact component={Auth(Agreement,true)}/>
          <Route path="/foreign-guest" exact component={Auth(ForeignGuest,true)}/>
          <Route component={Auth(PageNotFound, true)} />
        </Switch>
      </Suspense>
    </Layout>
  );

}
export default Routes;


