import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API_RESPONSE, USER_DATA, SELECTED_HOTEL_ID, SELECTED_HOTEL_NAME } from '../constants';
import { actionResponseHandler, actionErrorHandler } from '../components/utils/responseHandler';
import { checkSessionStatus, clearSession } from '../redux/actions/session_actions'
import {
	isMobile
} from "react-device-detect"
export default function (ComposedClass, isAuthRequired, adminRoute = null) {
	class AuthenticationCheck extends Component {

		state = {
			loading: true,
			userData: '',
			selectedHotelID: -1,
			selectedHotelName: ''
		}

		componentDidMount() {
			let response = null;
			let userData = JSON.parse(localStorage.getItem(USER_DATA));
			let token = userData ? userData.token : null;
			if (token && token.length > 20) {
				let apiData = {
					"login_user_id": userData.login_user_id,
					"login_user_name": userData.login_user_name,
					"login_user_type": userData.user_access_type
				}
				this.props.dispatch(checkSessionStatus(apiData)).then(() => {
					response = this.props.session;
					if (response.status === API_RESPONSE.SUCCESS) {
						// this.props.session.data["policy_type"]="NONE";
						let policy_type = this.props.session.data.policy_type;
						localStorage.setItem(USER_DATA, JSON.stringify(this.props.session.data));
						let userData = JSON.parse(localStorage.getItem(USER_DATA));

						let selectedHotel = this.props.session.data.hotels.find(
							hotel => hotel.hotel_id === response.selectedHotelID
						);

						// let agree_key=JSON.parse(localStorage.getItem("agree"));
						// localStorage.setItem(SELECTED_HOTEL_ID, userData.hotels[0].hotel_id);
						// localStorage.setItem(SELECTED_HOTEL_NAME, userData.hotels[0].hotel_name);
						let permissions = this.props.session.data.permissions
						// if (true){
						// 	this.props.history.push("/agreement");
						// }
						// if (policy_type == "DISPLAY" && this.props.match.path != "/policy-update" && this.props.match.path != "/policy") {
						// 	this.props.history.push("/policy-update");
						// }
						if (selectedHotel.agreement_type === "DISPLAY" && this.props.match.path != "/agreement") {
							this.props.history.push("/agreement")
						}
						if (selectedHotel.agreement_type !== "DISPLAY" && this.props.match.path != "/foreign-guest" && selectedHotel.allow_foreign_guest === "DISPLAY") {
							this.props.history.push("/foreign-guest")
						}
						if (!isAuthRequired) {
							this.props.history.push("/");
						}
						if (this.props.match.path === '/rates' && this.props.session.data.user_rate_access === 'na') {
							this.props.history.push("/noroute");
						}
						if (this.props.match.path === '/hotelfinance' && this.props.session.data.uep_finance === 'na') {
							this.props.history.push("/noroute");
						}
						if (this.props.match.path === '/profile' && permissions.uep_hotel_profile === '0') {
							this.props.history.push("/noroute");
						}
						if (this.props.match.path === '/inventory' && permissions.user_rate_access === 'na') {
							this.props.history.push("/noroute");
						}
						if (this.props.match.path === '/bookings' && permissions.uep_view_bookings === '0') {
							this.props.history.push("/noroute");
						}
						if (this.props.match.path === '/amenities' && permissions.uep_amenities === '0') {
							this.props.history.push("/noroute");
						}
						if (this.props.match.path === '/user-access' && permissions.uep_add_manager === '0') {
							this.props.history.push("/noroute");
						}

						if (this.props.match.url === '/internal/coupon' && permissions.uep_marketing === '0') {
							this.props.history.push("/noroute");
						}
						if (this.props.match.url === '/internal/admin-dashboard' && permissions.uep_admin_dashboard === '0') {
							this.props.history.push("/noroute");
						}
						if (this.props.match.url === '/internal/unlisted-hotels' && permissions.uep_hotel_listing === '0' && permissions.uep_closed_hotels === "0") {
							this.props.history.push("/noroute");
						}
						if (this.props.match.url === '/internal/finance' && permissions.uep_finance_inhouse === '0') {
							this.props.history.push("/noroute");
						}
						if (this.props.match.url === '/internal/hotel-balance-list' && permissions.uep_finance_inhouse === '0') {
							this.props.history.push("/noroute");
						}
						if (this.props.match.url === '/internal/activity-logs' && permissions.uep_finance_inhouse === '0') {
							this.props.history.push("/noroute");
						}
						if (this.props.match.url === '/internal/user-permissions' && permissions.uep_user_management === '0') {
							this.props.history.push("/noroute");
						}
						if (this.props.match.url === '/internal/s3' && permissions.uep_s3_bucket === '0') {
							this.props.history.push("/noroute");
						}

					} else {
						this.props.dispatch(clearSession());
						this.props.history.push("/");
					}
					this.setState({ loading: false });
				}).catch((error) => {
					this.setState({ loading: false })
				})
			} else {
				this.setState({ loading: false })
				if (isAuthRequired) {
					this.props.history.push('/login');
				} else {
				}
			}

		}

		// componentDidUpdate(){
		//     if(this.props.session && this.props.session.isSessionValid){
		//         if(!isAuthRequired){
		//             this.props.history.push('/bookings')
		//         }
		//     }else{
		//         if(isAuthRequired){
		//             this.props.history.push('/')
		//         }
		//     }
		// }


		render() {
			if (this.state.loading) {
				return (
					<div className="main_loader">
						{/* <CircularProgress style={{ color: '#2196F3' }} thickness={7} /> */}
					</div>
				)
			}
			return (
				<div>
					<div className={isMobile ? "mobile_app_container" : "app_container"}>
						<ComposedClass {...this.props} userData={this.props.session} />
					</div>
				</div>

			);
		}
	}

	const mapStateToProps = (state) => {
		return {
			session: state.session.response
		}
	}
	return connect(mapStateToProps)(AuthenticationCheck)
}


