import { TYPE } from '../actions/types'
export default function (state = {}, action) {
    switch (action.type) {
        case TYPE.HOTEL_LIST_FINANCE:
            return { ...state, response: action.payload }
        case TYPE.INITIAL_ENTRY:
            return { ...state, response: action.payload }
        case TYPE.GET_INVOICES:
            return { ...state, response: action.payload }
        case TYPE.LEDGER:
            return { ...state, response: action.payload }
        case TYPE.CREDIT_DEBIT_NOTE:
            return { ...state, response: action.payload }
        case TYPE.GET_DISPUTES:
            return { ...state, response: action.payload }
        case TYPE.RESOLVE_DISPUTE:
            return { ...state, response: action.payload }
        case TYPE.CREATE_DISPUTE:
            return { ...state, response: action.payload }
        case TYPE.GET_CD_NOTES:
            return { ...state, response: action.payload }
        case TYPE.DOWNLOAD:
            return { ...state, response: action.payload }
        case TYPE.HOTEL_FINANCE_SUMMARY:
            return { ...state, response: action.payload }
        case TYPE.GET_SALES_DATA:
            return { ...state, response: action.payload }
        case TYPE.HOTEL_PAYOUT:
            return { ...state, response: action.payload }
        case TYPE.LEDGER_PAY:
            return { ...state, response: action.payload }
        case TYPE.ACTIVITY_LOGS:
            return { ...state, response: action.payload }
        case TYPE.LEDGER_PAY_TRANSACTIONS:
            return { ...state, response: action.payload }
        default:
            return state;
    }
}

