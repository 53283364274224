import { combineReducers } from "redux";
import userResponse from "./user_reducer";
import bookingResponse from "./booking_reducer";
import availabilityResponse from "./availability_reducer";
import rateResponse from "./rates_reducer";
import roomResponse from "./rooms_reducer";
import session from "./session_reducer";
import hotelProfile from "./profile_reducer";
import settingUpdate from "./sa_reducer";
import amentiesResponse from "./amenties_reducers";
import policyUpdateResponse from "./policy_reducer";
import checkinResponse from "./checkIn_reducers";
import updateCheckinResponse from "./checkIn_reducers"
import userAccessResponse from "./userAccess_reducer"
import hotelListingResponse from "./hotel_listing_reducer"
import bankAccountResponse from "./bank_account_reducer";
import adminResponse from "./admin_reducer"
import hotelFinanceResponse from "./finance_reducer";
import agreementResponse from "./agreement_reducers";


const rootReducer = combineReducers({
  userResponse,
  bookingResponse,
  availabilityResponse,
  rateResponse,
  roomResponse,
  session,
  hotelProfile,
  settingUpdate,
  amentiesResponse,
  policyUpdateResponse,
  checkinResponse,
  updateCheckinResponse,
  userAccessResponse,
  hotelListingResponse,
  bankAccountResponse,
  adminResponse,
  hotelFinanceResponse,
  agreementResponse,

  });

export default rootReducer;
