import React, { Component } from "react";
import {
  Segment,
  Image,
  Icon,
  Button,
  Menu,
  Sidebar
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { Suspense, lazy } from 'react';
import {
  USER_DATA,
  SELECTED_HOTEL_ID,
  SELECTED_HOTEL_NAME
} from "../../../constants";
import { logout } from "../../../redux/actions/user_actions";
import { clearSession } from "../../../redux/actions/session_actions";
import { connect } from "react-redux";
import {
  isMobile
} from "react-device-detect"
import MenuList from "./menuItem_m";
import * as Constants from '../../../constants';

const HeaderComponentWeb = React.lazy(() => import('../Header/headerComponent'));
const HeaderComponentMobile = React.lazy(() => import('../Header/headeComponent_m'));

class Header extends Component {
  state = {
    activeItem: "/bookings",
    visible: false,
    label: "My Bookings",
    icon: "calendar alternate",
    financeMenuVisible: false,
    userType: "",
    isAdminAvailable: false
  };

  getMenuList = () => {
    let unlistedClosedLabel = ""
    let permissions = this.props.session.data.permissions
    let isRateForUser = this.props.session.data.permissions.uep_user_rate_access !== "na";
    let isSuperAdmin = this.props.session.data.user_access_type === "SUPER_ADMIN";
    let isAdmin = this.props.session.data.user_access_type === "ADMIN";
    let menuList = [];
    let subMenulist = [];
    let isHotelClosed = 0
    if (permissions.uep_hotel_listing == 1 && permissions.uep_closed_hotels == 1) {
      unlistedClosedLabel = ["Unlisted/Closed Hotels"]
      isHotelClosed = 1
    }
    else if (permissions.uep_hotel_listing == 1 && permissions.uep_closed_hotels == 0) {
      unlistedClosedLabel = "Unlisted Hotels"
    }
    else if (permissions.uep_hotel_listing == 0 && permissions.uep_closed_hotels == 1) {
      unlistedClosedLabel = "Closed Hotels"
    }
    if (permissions.uep_view_bookings == "1") {
      menuList.push({
        label: "Bookings",
        pathValue: "/bookings",
        icon: "calendar alternate",
        type: "non-admin",
        isAvailableForUser: true
      })
    }

    if (isRateForUser || permissions.uep_inventories == "1") {
      menuList.push({
        label: "Manage Rates / Rooms",
        pathValue: "/inventory",
        icon: "building",
        type: "non-admin",
        isAvailableForUser: isRateForUser
      });
    }
    if (permissions.uep_amenities == "1") {
      menuList.push(
        {
          label: "Amenities",
          pathValue: "/amenities",
          icon: "bed",
          type: "non-admin",
          isAvailableForUser: true
        }
      )
    }
    menuList.push(
      {
        label: "Policy Updates",
        pathValue: "/policy",
        icon: "file alternate",
        type: "non-admin",
        isAvailableForUser: true
      },
    );
    if (permissions.uep_finance == "1") {
      menuList.push({
        label: "Hotel Finance",
        pathValue: "/hotelfinance",
        icon: "money bill alternate",
        type: "non-admin",
        isAvailableForUser: true
      })
    }
    if (permissions.uep_add_manager == "1") {
      menuList.push({
        label: "User Access",
        pathValue: "/user-access",
        icon: "user circle",
        type: "non-admin",
        isAvailableForUser: true
      });
    }
    if (permissions.uep_hotel_profile == "1") {
      menuList.push({
        label: "Profile",
        pathValue: "/profile",
        icon: "user",
        type: "non-admin",
        isAvailableForUser: true
      })
    }
    if (isSuperAdmin) {
      menuList.push({
        label: "Go to Admin",
        pathValue: "/admin",
        icon: "long arrow alternate right",
        color: "#3377ab",
        type: "admin",
        isAvailableForUser: true,
        subMenulist: subMenulist

      })
    }


    return menuList;
  };


  handleItemClick = (e, { name, label, value, type }) => {
    console.log("type===>", name, value, label, e.target)
    if (name == "/admin") {
      window.open(Constants.ADMINCHECKOUT, '_blank');
    } else {

      this.setState({ visible: false });
      this.setState({ activeItem: name });
      this.setState({ label: label });
      this.setState({ icon: value })
      // this.setState({ userType: type })
      this.props.history.push(name);
    }
  };

  handleChangedValue = (event, valueSet) => {
    let userData = JSON.parse(localStorage.getItem(USER_DATA));
    let selectedHotel = userData.hotels.find(
      hotel => hotel.hotel_id === valueSet.value
    );
    localStorage.setItem(SELECTED_HOTEL_ID, selectedHotel.hotel_id);
    localStorage.setItem(SELECTED_HOTEL_NAME, selectedHotel.hotel_name);
    if (selectedHotel.agreement_type === "DISPLAY") {
      this.props.history.push("/agreement");
    }
    else if (selectedHotel.agreement_type !== "DISPLAY"
      && selectedHotel.allow_foreign_guest === "DISPLAY") {
      this.props.history.push("/foreign-guest")
    }
    else {
      this.props.history.push("/redirect");
      setTimeout(() => {
        this.props.history.push("/bookings");
      }, 2);
      this.setState({
        activeItem: this.getMenuList()[0].pathValue,
        label: this.getMenuList()[0].label,
        icon: this.getMenuList()[0].icon
      });
    }

  };

  logoutUser = () => {
    let userData = JSON.parse(localStorage.getItem(USER_DATA));
    let data = {
      "login_user_id": userData.login_user_id,
      "login_user_name": userData.login_user_name,
      "login_user_type": userData.user_access_type
    }
    this.props.dispatch(logout(data));
    this.props.dispatch(clearSession());
    this.props.history.push("/login");
  };

  isUserHeader = () => {
    let userData = JSON.parse(localStorage.getItem(USER_DATA));
    return userData && userData.token && userData.token.length > 20;
  };

  getHotelList = () => {
    let userData = JSON.parse(localStorage.getItem(USER_DATA));
    return userData.hotels.map(hotel => ({
      value: hotel.hotel_id,
      text: hotel.hotel_name,
      key: hotel.hotel_name
    }));
  };

  sidebarOpenClick = () => {
    this.setState({ visible: !this.state.visible });
  };
  sidebarCloseClick = () => {
    this.setState({ visible: false });
  };
  changePassword = () => {
    this.props.history.push("/password-change");
  }

  // finance
  getMenuListFinance = () => {
    let menuList = [];
    menuList.push(
      {
        label: "Ledger",
        name: "Ledger",
        color: "white",
        pathValue: "/finance/ledger",
        key: "Ledger",
        icon: "calendar alternate",

      },
      {
        label: "Hotel Balance List",
        name: "Hotel Balance List",
        pathValue: "/finance/hotel-balance-list",
        color: "white",
        key: "hotelFinance",
        icon: "calendar alternate",
      },
      {
        label: "Invoice Generation",
        name: "Invoice Generation",
        pathValue: "/finance/invoice-generation",
        color: "white",
        key: "Cr/Dr",
        icon: "calendar alternate",

      }
    )
    return menuList;
  }

  financeSidebarAction = () => {
    this.setState({ financeMenuVisible: !this.state.financeMenuVisible });
  }


  render() {
    const { activeItem } = this.state;
    return (
      <div>
        {Constants.debug == 0 ? null : <div style={{ fontSize: "1.5em", fontWeight: "bold", textAlign: "center" }}>Test</div>}

        {this.props.session && this.props.session.isSessionValid ? (

          isMobile ?


            <div>
              <Suspense fallback>
                <HeaderComponentMobile
                  logoutUser={this.logoutUser}
                  changePassword={this.changePassword}
                  getHotelList={this.getHotelList}
                  getMenuList={this.getMenuList}
                  handleItemClick={this.handleItemClick}
                  handleChangedValue={this.handleChangedValue}
                  sidebarOpenClick={this.sidebarOpenClick}
                  sidebarCloseClick={this.sidebarCloseClick}
                  state={this.state}
                  {...this.props} />
              </Suspense>
            </div >
            :
            // this.props.location.pathname === "/:id"
            this.props.location.pathname.includes("/register-hotel")
              &&
              this.props.session.data.permissions.uep_hotel_listing == "1"
              ?
              <Segment.Group className="header-config">
                <Segment textAlign="center" className="colorPrimary">
                  <span>
                    <Image
                      wrapped
                      verticalAlign="middle"
                      src="https://s3.ap-south-1.amazonaws.com/site-img-res-new/logo-mail.png"
                      size="small"
                    />
                    <p style={{ color: "#fff" }}>Edit/Complete Hotel Listing</p>
                  </span>
                </Segment>


              </Segment.Group>
              :
              this.props.location.pathname === "/admin-registration" ?
                < Segment.Group className="header-config">
                  <Segment textAlign="center" className="colorPrimary">
                    <span>
                      <Image
                        wrapped
                        verticalAlign="middle"
                        src="https://s3.ap-south-1.amazonaws.com/site-img-res-new/logo-mail.png"
                        size="small"
                      />
                      <p style={{ color: "#fff" }}>Admin Registration</p>
                    </span>
                  </Segment>
                </Segment.Group>
                :
                this.props.location.pathname === "/bulk-account-search" ?
                  < Segment.Group className="header-config">
                    <Segment textAlign="center" className="colorPrimary">
                      <span>
                        <Image
                          wrapped
                          verticalAlign="middle"
                          src="https://s3.ap-south-1.amazonaws.com/site-img-res-new/logo-mail.png"
                          size="small"
                        />
                        <p style={{ color: "#fff" }}>Bulk Account Search</p>
                      </span>
                    </Segment>


                  </Segment.Group>

                  :

                  this.props.location.pathname === "/bulk-admin-search" ?
                    < Segment.Group className="header-config">
                      <Segment textAlign="center" className="colorPrimary">
                        <span>
                          <Image
                            wrapped
                            verticalAlign="middle"
                            src="https://s3.ap-south-1.amazonaws.com/site-img-res-new/logo-mail.png"
                            size="small"
                          />
                          <p style={{ color: "#fff" }}>Bulk Admin Search</p>
                        </span>
                      </Segment>


                    </Segment.Group>
                    :

                    <div>
                      < Suspense fallback>
                        <HeaderComponentWeb
                          logoutUser={this.logoutUser}
                          changePassword={this.changePassword}
                          getHotelList={this.getHotelList}
                          getMenuList={this.getMenuList}
                          handleItemClick={this.handleItemClick}
                          handleChangedValue={this.handleChangedValue}
                          permissions={this.props.session.data.permissions}
                          state={this.state}
                          {...this.props} />
                      </Suspense>
                    </div>

        ) : (
          <Segment.Group className="header-config">
            <Segment textAlign="center" className="colorPrimary">
              <span>
                <Image
                  wrapped
                  verticalAlign="middle"
                  src="https://s3.ap-south-1.amazonaws.com/site-img-res-new/logo-mail.png"
                  size="small"
                />
                <p style={{ color: "#fff" }}>  Extranet</p>
              </span>
            </Segment>


          </Segment.Group>
        )
        }
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    session: state.session.response
  };
};
export default connect(mapStateToProps)(withRouter(Header));